import { useLocation } from "@reach/router"
import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../../hooks/useSiteMetadata"
import { RecipePage } from "../../models"
import JsonLd from "./JsonLd"
import { SeoProps } from "./types"

const Seo: FunctionComponent<SeoProps> = ({ metadata, children }) => {
  const { pathname } = useLocation()
  const siteMetadata = useSiteMetadata()

  const siteTitle = metadata?.title
    ? `${metadata.title} - ${siteMetadata.title}`
    : siteMetadata.title
  const seo = {
    title: siteTitle,
    description: metadata?.description || siteMetadata.description,
    image: metadata?.image || siteMetadata.siteUrl + siteMetadata.image,
    url: `${siteMetadata.siteUrl}${pathname}`,
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isRecipePage(arg: any): arg is RecipePage {
    return arg?.ingredients !== undefined
  }

  const jsonLd = new JsonLd(siteMetadata)
  let schemaOrgWebPage = null
  let schemaOrgRecipe = null
  if (isRecipePage(metadata)) {
    schemaOrgRecipe = jsonLd.recipe(metadata)
  } else {
    schemaOrgWebPage = jsonLd.webPage(metadata)
  }

  const breadcrumb = jsonLd.breadcrumb()

  return (
    <Helmet title={seo.title}>
      <html lang={siteMetadata.siteLanguage} />
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="image" content={seo.image} />}
      {siteMetadata.facebook && (
        <meta property="og:site_name" content={siteMetadata.facebook} />
      )}
      {siteMetadata.siteLanguage && (
        <meta property="og:locale" content={siteMetadata.siteLanguage} />
      )}
      <meta property="og:type" content={"website"} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.description} />
      <meta name="p:domain_verify" content={siteMetadata.pinterest} />
      <script type="application/ld+json" id="b">
        {JSON.stringify(breadcrumb)}
      </script>
      {schemaOrgRecipe && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgRecipe)}
        </script>
      )}
      {schemaOrgWebPage && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {children}
    </Helmet>
  )
}

export default Seo
