import { graphql, Link, useStaticQuery } from "gatsby"
import React, { FunctionComponent } from "react"
import { MainNavigation, Website } from "../../models"
import SearchForm from "../SearchForm"
import * as styles from "./header.module.scss"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps {
  showSearchbox: boolean
  useH1: boolean
}

interface MainNavigationQuery {
  mainNavigation: MainNavigation
  site: Website
}

const query = graphql`
  query MainNavigation {
    mainNavigation: contentfulMainNavigation {
      id
      navigationItems {
        __typename
        ... on Node {
          ... on ContentfulRecipe {
            id
            slug: slug
            navigationTitle: title
          }
          ... on ContentfulCategory {
            id
            slug: slug
            navigationTitle: name
          }
        }
      }
    }
    site {
      ...SiteMetadata
    }
  }
`

const Header: FunctionComponent<HeaderProps> = ({ showSearchbox, useH1 }) => {
  const { mainNavigation, site } = useStaticQuery<MainNavigationQuery>(query)

  return (
    <header className={styles.base}>
      {useH1 && (
        <h1 className={styles.name}>
          <Link to="/">{site.siteMetadata.title}</Link>
        </h1>
      )}
      {!useH1 && (
        <div className={`site-title ${styles.name}`}>
          <Link to="/">{site.siteMetadata.title}</Link>
        </div>
      )}
      <nav className={styles.navigation}>
        <input className={styles.menuBtn} type="checkbox" id="menu-btn" />
        <label className={styles.hamburger} htmlFor="menu-btn">
          <span className={styles.lineTop}></span>
          <span className={styles.lineCenter}></span>
          <span className={styles.lineBottom}></span>
        </label>
        <ul className={styles.navList}>
          {mainNavigation.navigationItems.map((navigationItem, index) => (
            <li key={index}>
              <Link
                to={`/${navigationItem.slug}`}
                activeClassName={styles.active}>
                {navigationItem.navigationTitle}
              </Link>
            </li>
          ))}
          <li>
            <Link to="/">Alle recepten</Link>
          </li>
        </ul>
      </nav>
      {showSearchbox && (
        <div className={styles.search}>
          <SearchForm />
        </div>
      )}
    </header>
  )
}

export default Header
