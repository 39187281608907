import React, { FunctionComponent } from "react"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => {
  return <div></div>
}

export default Footer
