import { useLocation } from "@reach/router"
import { getSrc } from "gatsby-plugin-image"
import { first } from "lodash"
import {
  Article,
  BreadcrumbList,
  ItemList,
  ListItem,
  Organization,
  Recipe,
  SearchAction,
  WebPage,
  WebSite,
  WithContext,
} from "schema-dts"
import { Metadata, RecipePage, RichTextJson, SiteMetadata } from "../../models"

type SearchActionWithQueryInput = SearchAction & {
  "query-input": string
}
export default class JsonLd {
  siteMetadata: SiteMetadata
  webSite: WithContext<WebSite>
  publisher: WithContext<Organization>

  constructor(siteMetadata: SiteMetadata) {
    this.siteMetadata = siteMetadata

    this.publisher = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: this.siteMetadata.title,
      url: this.siteMetadata.siteUrl,
      logo: {
        "@type": "ImageObject",
        "@id": this.siteMetadata.siteUrl + "/#logo",
        url: this.siteMetadata.image,
        width: "0px",
        height: "0px",
        caption: "..",
      },
    }

    const search: SearchActionWithQueryInput = {
      "@type": "SearchAction",
      target: `${this.siteMetadata.siteUrl}?query={search_term_string}`,
      "query-input": "required name=search_term_string",
    }

    this.webSite = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "@id": this.siteMetadata.siteUrl,
      url: this.siteMetadata.siteUrl,
      name: this.siteMetadata.title,
      inLanguage: this.siteMetadata.siteLanguage,
      description: this.siteMetadata.description,
      publisher: this.publisher,
      potentialAction: search,
    }
  }
  recipe(recipePage: RecipePage): WithContext<Recipe> | void {
    if (!recipePage) {
      return undefined
    }

    const ingredients: string[] = []
    const rawIngredients = JSON.parse(
      recipePage.ingredients.raw
    ) as RichTextJson
    rawIngredients.content.forEach((content) => {
      content.content.forEach((listItem) => {
        if (listItem.nodeType === "list-item") {
          listItem.content.forEach((paragraph) => {
            if (paragraph.nodeType === "paragraph") {
              paragraph.content.forEach((text) => {
                ingredients.push(text.value)
              })
            }
          })
        }
      })
    })

    const instructions: ItemList[] = []
    const rawDirections = JSON.parse(recipePage.directions.raw) as RichTextJson
    rawDirections.content.forEach((content) => {
      content.content.forEach((listItem) => {
        if (listItem.nodeType === "list-item") {
          listItem.content.forEach((paragraph) => {
            if (paragraph.nodeType === "paragraph") {
              paragraph.content.forEach((text) => {
                instructions.push({
                  "@type": "HowToStep",
                  text: text.value,
                })
              })
            }
          })
        }
      })
    })

    const primaryCategory = first(recipePage.categories)

    const article: WithContext<Article> = this.article(
      recipePage
    ) as WithContext<Article>

    const featureImageSrc = getSrc(
      recipePage.featuredImage.localFile.childImageSharp.gatsbyImageData
    )
    const recipe: WithContext<Recipe> = {
      "@context": "https://schema.org",
      "@type": "Recipe",
      name: recipePage.title,
      image: ["https:" + featureImageSrc],
      author: {
        "@type": "Person",
        name: this.siteMetadata.author,
      },
      dateCreated: recipePage.publishDate,
      dateModified: recipePage.updateAt,
      description: recipePage.description,
      recipeCuisine: "American",
      prepTime: recipePage.prepTime,
      cookTime: recipePage.cookTime,
      totalTime: recipePage.totalTime,
      keywords: primaryCategory?.name,
      recipeYield: recipePage.servings,
      recipeCategory: primaryCategory?.name,
      recipeIngredient: ingredients,
      recipeInstructions: instructions,
      isPartOf: article,
    }

    return recipe
  }

  webPage(metadata?: Metadata): WithContext<WebPage> {
    const { pathname } = useLocation()
    if (metadata) {
      const url = `${this.siteMetadata.siteUrl}${pathname}`
      metadata.url = url
    }

    const year = metadata?.createdAt
      ? new Date(metadata.createdAt).getFullYear()
      : new Date().getFullYear()

    const webPage: WithContext<WebPage> = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      url: metadata?.url ?? this.siteMetadata.siteUrl,
      headline: metadata?.title ?? this.siteMetadata.title,
      inLanguage: this.siteMetadata.siteLanguage,
      mainEntityOfPage: metadata?.url ?? this.siteMetadata.siteUrl,
      description: metadata?.description ?? this.siteMetadata.description,
      name: metadata?.title ?? this.siteMetadata.title,
      author: {
        "@type": "Person",
        name: metadata?.author ?? this.siteMetadata.author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: metadata?.author ?? this.siteMetadata.author,
      },
      copyrightYear: year,
      creator: {
        "@type": "Person",
        name: metadata?.author ?? this.siteMetadata.author,
      },
      publisher: {
        "@type": "Person",
        name: metadata?.author ?? this.siteMetadata.author,
      },
      datePublished: metadata?.createdAt,
      dateModified: metadata?.updateAt,
      image: {
        "@type": "ImageObject",
        url: `${this.siteMetadata.siteUrl}${
          metadata?.image ?? this.siteMetadata.image
        }`,
      },
      isPartOf: this.webSite,
    }

    return webPage
  }

  article(recipePage?: RecipePage): WithContext<Article> | void {
    if (!recipePage) {
      return undefined
    }
    const { pathname } = useLocation()

    const webPage: WithContext<WebPage> = this.webPage(recipePage)

    const featureImageSrc = getSrc(
      recipePage.featuredImage.localFile.childImageSharp.gatsbyImageData
    )
    const article: WithContext<Article> = {
      "@context": "https://schema.org",
      "@type": "Article",
      url: recipePage.url + "/#Article",
      mainEntityOfPage: this.siteMetadata.siteUrl + pathname,
      headline: recipePage.title,
      datePublished: recipePage.createdAt,
      dateCreated: recipePage.createdAt,
      dateModified: recipePage?.updateAt ?? recipePage?.createdAt,
      inLanguage: this.siteMetadata.siteLanguage,
      articleSection: recipePage.categories.map((x) => x.name),
      image: ["https:" + featureImageSrc],
      author: {
        "@type": "Person",
        name: this.siteMetadata.author,
      },
      publisher: this.publisher,
      isPartOf: webPage,
    }

    return article
  }

  breadcrumb(): WithContext<BreadcrumbList> {
    const itemListElement: ListItem[] = [
      {
        "@type": "ListItem",
        item: {
          "@id": this.siteMetadata.siteUrl,
          name: "Homepage",
        },
        position: 1,
      },
    ]

    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      description: "Breadcrumbs list",
      name: "Breadcrumbs",
      itemListElement: itemListElement,
    }
  }
}
