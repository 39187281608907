import { navigate } from "gatsby"
import * as React from "react"
import { FunctionComponent, useRef } from "react"
import ClearIcon from "../../assets/images/clear.svg"
import SearchIcon from "../../assets/images/search.svg"
import * as styles from "./searchform.module.scss"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchFormProps {}

const SearchForm: FunctionComponent<SearchFormProps> = () => {
  const input = useRef<HTMLInputElement>(null)
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    if (input && input.current) {
      const q = input.current.value
      navigate("/zoeken/?query=" + q, { replace: true })
    }
  }

  return (
    <form
      action="/zoeken"
      method="GET"
      autoComplete="on"
      role="search"
      onSubmit={handleSubmit}
      noValidate={true}
      className={styles.sbxMedium}>
      <div role="search" className={styles.sbxMedium__wrapper}>
        <button
          type="submit"
          title="Voer uw zoekterm in"
          id="searchSubmit"
          className={styles.sbxMedium__submit}>
          <SearchIcon aria-hidden={true} role="img" />
        </button>
        <input
          type="search"
          name="search"
          placeholder="Zoek een recept"
          autoComplete="off"
          required
          aria-labelledby="searchSubmit"
          className={styles.sbxMedium__input}
          ref={input}
        />
        <button
          type="reset"
          title="Reset de zoekterm"
          className={styles.sbxMedium__reset}>
          <ClearIcon aria-hidden={true} role="img" />
        </button>
      </div>
    </form>
  )
}

export default SearchForm
