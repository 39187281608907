import React, { FunctionComponent, ReactNode } from "react"
import "../../style/index.scss"
import Footer from "../Footer"
import Header from "../Header"
import * as styles from "./index.module.scss"

interface LayoutProps {
  showSearchbox: boolean
  useH1Header: boolean
  children: ReactNode
}

const Layout: FunctionComponent<LayoutProps> = ({
  showSearchbox,
  useH1Header,
  children,
}) => {
  return (
    <main>
      <div className={styles.gridContainer}>
        <div className={styles.header}>
          <Header showSearchbox={showSearchbox} useH1={useH1Header} />
        </div>
        <div className={styles.main}>{children}</div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </main>
  )
}

export default Layout
